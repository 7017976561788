import React, { useState, useEffect, useContext, useRef } from 'react';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import uuid from 'react-uuid';



import { DbAgentTunnels, DbAgentTunnelsSet, DbAgentTunnelDelete } from '../db/agents';
import { DatacastContext } from '../context/datacastcloud';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
const Tunnels = ({agent}) => {
 
    const [refreshKey, setRefreshKey] = useState(0);
    const [ datacastProperties ] = useContext(DatacastContext);
    const [ tunnels, setTunnels ] = useState([]);
    
    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [keyPath, setKeyPath] = useState('');
    

    useEffect(() => {

        const GetData = async (datacastProperties) => {

            const _agent = await DbAgentTunnels(datacastProperties.ip, agent.uuid);
            if(_agent.keyPath && _agent.keyPath.length > 0) {
                setKeyPath(_agent.keyPath);
            }
            else {
                if(agent.type === 'GATEWAY') {
                    setKeyPath('/working/data/tunnels/id_rsa');
                }
                else {
                    setKeyPath('/map/id_rsa');
                }
            }

            
            setTunnels(_agent.agent_tunnels);
        }

        GetData(datacastProperties).catch((error)=>{
            console.error(error);
        })

    },[datacastProperties, refreshKey]); 

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue}</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-1 p-2" style={{ fontSize: '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-3">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const deleteTunnel = (event, rowData) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete this tunnel?',
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>accept(rowData),
            reject
        });
    }

    const accept = (rowData) => {

        DbAgentTunnelDelete(datacastProperties.ip, rowData.id).then((result)=>{
            showResult('success', `Delete succeeded`, 'Tunnel delete succeeded');
            setRefreshKey((old)=>old+1);
        }).catch((error)=>{
            console.error(error);
            showResult('error', `Failed`, 'Could not delete tunnel');
        })

    }

    const reject = () => {
        showResult('warn', `Delete canceled`, 'Tunnel delete canceled');
    }

    const showResult = (severity, summary, detail) => {
        toast.current.show({severity:severity, summary:summary, detail:detail, life: 3000});
    }

    const cellEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />;
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        if (newValue > 0) {
            rowData[field] = newValue;

            DbAgentTunnelsSet(datacastProperties.ip, agent.uuid, rowData).then((result)=>{
                showResult('success', `Updated`, 'Tunnels updated successfully');
            }).catch((error)=>{
                console.error(error);
                showResult('error', `Failed`, 'Tunnel could not be updated');
            })
        }
        else {
            event.preventDefault();
        }
        
    };

    //Enabled/disabbled.
    const onCheckEditComplete = (tunnelId, newValue) => {
        DbAgentTunnelsSet(datacastProperties.ip, agent.uuid, {id:tunnelId, enabled:newValue }).then((result)=>{
            showResult('success', `Enabled`, `Tunnel has been ${newValue === 1?'enabled':'disabled'}`);
            setRefreshKey((old)=>old+1);
        }).catch((error)=>{
            console.error(error);
            showResult('error', `Failed`, 'Tunnel could not be enabled');
        })
    }

    const enabledBodyTemplate = (rowData) => {
        return (
            <Button className={(rowData.enabled > 0)?'p-button-text p-button-success':'p-button-text p-button-danger'} icon={(rowData.enabled > 0)?'pi pi-check':'pi pi-ban'} onClick={(e)=>onCheckEditComplete(rowData.id, rowData.enabled?0:1)} />
        );
    }

    const editBodyTemplate = (rowData) => {

        return(
            <>
               <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=>deleteTunnel(e, rowData)}/>
            </>
        );
    }

    const addTunnel = () => {
        const _tunnel = {uuid:uuid(), localPort:22, hostPort:7781, enabled:0 };

        DbAgentTunnelsSet(datacastProperties.ip, agent.uuid, _tunnel).then((result)=>{
            showResult('success', `Created`, 'Created new tunnel successfully');
            setRefreshKey((old)=>old+1);
        }).catch((error)=>{
            console.error(error);
            showResult('error', `Failed`, 'New tunnel could not be created');
        })
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup />
            <div className='grid'>
                <div className='col-12'>
                    <DataTable value={tunnels} editMode="cell" responsiveLayout="scroll" >
                        {/*<Column field="uuid" header="UUID"  ></Column>*/}
                        <Column field='localPort' header="Agent Port" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                        <Column field='hostPort' header="Cortex Port" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} ></Column>
                        <Column field='enabled' header="Enabled" body={(options)=>enabledBodyTemplate(options)} ></Column>
                        <Column field='Edit' body={editBodyTemplate} style={{ width: '10%' }}></Column>
                    </DataTable>
                </div>
                <div className={`col-12 flex align-items-center align-content-center mt-2`}>
                    <div><Button icon="pi pi-plus" className="p-button-rounded bg-blue-600" onClick={()=>addTunnel()}/></div><div className='pl-1'>Add Tunnel</div>
                </div>
                <div className='col-12'>
                    <div className='flex w-full'><div className='font-semibold p-2'>SSH Key Upload</div><div className='w-7'><InputText className="border-none w-full" value={keyPath} onChange={(e) => setKeyPath(e.target.value)} /></div></div>
                </div>
                <div className="col-12">
                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                    <FileUpload ref={fileUploadRef}
                    name='key_upload' 
                    multiple={false}
                    url={`/api/v1/mistiq/agents/${agent.uuid}/upload?keyPath=${keyPath}`} 
                    onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                    cancelOptions={cancelOptions} chooseOptions={chooseOptions} uploadOptions={uploadOptions}  
                    maxFileSize={4096} 
                    />
                </div>
            </div>
        </>
    
    )
}

export default Tunnels;