import React, {useState, useRef, useContext, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Link, Route, Navigate} from 'react-router-dom';

import { useServiceWorker } from './hooks/useServiceWorker';
import { useIdleTimer } from 'react-idle-timer';

import "primereact/resources/primereact.min.css";
import 'primereact/resources/themes/fluent-light/theme.css';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import { Image } from 'primereact/image';

import About from './about/about';
import SrtServer from './srtserver/srtserver';
import Servers from './servers/servers';
import Streams from './streams/streams';
import PathAnalysis from './analysis/paths';
import Admin from './admin/admin';
import CreateGroup from './admin/creategroup';
import ServerAnalysis from './analysis/servers';
import StreamAnalysis from './analysis/streams';

import Alerts from './alerts/alerts';
import Agents from './agents/agents';
import Settings from './settings/settings';
import EditAgent from './agents/editagent';
import ViewAgent from './agents/view';
import EditStream from './stream/editstream';
import CreateAgent from './agents/createagent';
import AddStream from './stream/addstream';
import Status from './status/status';
import AlertNotification from './alerts/alertnotification';

import './App.css';
import Login from './user/login';
import CreateCortex from './cortex/cortex';
import CreateUser from './user/createuser';
import { DbCortex } from './db/cortex';

import { DatacastContext } from './context/datacastcloud';
import EditGroup from './admin/groups';
import { Button } from 'primereact/button';
import CreateAgentGroup from './admin/createagentgroup';
import EditAgentGroup from './admin/agentgroups';
import { DbUserSessionClose } from './db/users';
import { DbAgentTunnelDref } from './db/agents';


function App() {
  const btnRef5 = useRef(null);
  const btnRef6 = useRef(null);
  const btnRef4 = useRef(null);
  const [showAbout, setShowAbout] = useState(false);
  const [systemInitialized, setSystemInitialized] = useState(false);
  const [acceptUpdate, setAcceptUpdate] = useState(true);


  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  
  const [datacastProperties, setDatacastProperties] = useContext(DatacastContext);

  const datacastIp = datacastProperties.ip;

  //run only on load
  useEffect(()=>{
    const handleTabClose = (event) => {
      
      
      const sessionId = sessionStorage.getItem("session_id");
      if(sessionId) {
        DbUserSessionClose(datacastProperties.userIp, {id:sessionId}).then((result)=>{
          sessionStorage.removeItem("session_id");
        });
      }
      
      //Delete any old tunnels
      const tunnelId = sessionStorage.getItem("tunnel_id");
      if(tunnelId) {
        DbAgentTunnelDref(datacastProperties.userIp, {id:tunnelId}).then((result)=>{
          sessionStorage.removeItem("tunnel_id");
        });
      }

      event.preventDefault();
      return (event.returnValue ='');
    }

    window.addEventListener('beforeunload', handleTabClose);

    return (()=>{
      window.removeEventListener('beforeunload', handleTabClose);
    })
  },[]);

  useEffect(()=>{
    DbCortex(datacastProperties.userIp).then((result) => {
      
      if(result.error === 0) {
        
        const _cortex = result.data;
        if(_cortex) {

          setDatacastProperties(
            {
              ...datacastProperties, 
              networkName:_cortex.name, 
              networkKey:_cortex.uuid, 
              ip:_cortex.cloudIp, 
              sshIp:_cortex.sshIp, 
              portMin:_cortex.portMin, 
              portMax:_cortex.portMax
            }
          );
          
          setSystemInitialized(true);
          
        }
      }

    }).catch((error) => {
        console.error(error);
    });


    if (acceptUpdate && showReload && waitingWorker) {

      const reject = () => {
        setAcceptUpdate(false);
      }

      confirmDialog({
        message: 'A new version of MISTiQ is available!',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        position: 'top',
        acceptLabel: 'UPDATE',
        rejectLabel: 'CANCEL',
        className: 'flex justify-content-center',
        accept:()=>reloadPage(),
        reject:()=>reject()
      });
    }

  },[waitingWorker, showReload, reloadPage])


  const logout = async () => {
    const sessionId = sessionStorage.getItem("session_id");
    console.log(`Closing session ${sessionId}`);
    if(sessionId) {
      DbUserSessionClose(datacastProperties.userIp, {id:sessionId}).then((result)=>{
        sessionStorage.removeItem("session_id");
        setDatacastProperties((prevState)=>({...prevState, session:null}));
      });
    }
    
    setDatacastProperties((prevState)=>({...prevState, loggedIn:false, session:null}));
  };

  const onIdle = () => {
    logout();
  }

  //Enable this to have people logged out after a certain period.
  const idleTimer = useIdleTimer({onIdle, timeout:15*60*1000})

  
  if(datacastProperties.loggedIn !== true){
    return (
      <Router>
         <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/createuser" exact element={<CreateUser />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
      </Router>
    );
  }

  if(systemInitialized) {
    return (
      <Router>      
        <Dialog header="MISTiQ UI" visible={showAbout}  onHide={()=>setShowAbout(false)} >
          <About />
        </Dialog>
        <div className="min-h-screen flex relative lg:static surface-ground">
          <div id="app-sidebar-1" className="surface-600 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none" style={{ width: '250px' }}>
            {/*Sidebar*/}
            <div className="flex flex-column h-full">
              {/*Logo*/}
              <div className="flex align-items-center px-5 surface-600 flex-shrink-0" style={{ height: '80px' }}>
                <Image src="img/logo_white.png" alt="White Logo" height={30} />
              </div>
            
              {/*Menu*/}
              <div className='overflow-y-auto mt-3'>
                <ul className="list-none p-3 m-0">
                  <li>
                      <Link to="/" style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                          <i className="pi pi-home mr-2"></i>
                          <span>STATUS</span>
                          <Ripple />
                      </Link>
                  </li>
                  <li>
                      <Link to="/agents" style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                          <i className="pi pi-users mr-2"></i>
                          <span>AGENTS</span>
                          <Ripple />
                      </Link>
                  </li>
                  {(datacastProperties.admin)?
                    <>
                      <li>
                          <Link to="/servers" style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                              <i className="pi pi-server mr-2"></i>
                              <span>SERVERS</span>
                              <Ripple />
                          </Link>
                      </li>
                      <li>
                        <Link to="/streams" style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                            <i className="pi pi-table mr-2"></i>
                            <span>DETAILS</span>
                            <Ripple />
                        </Link>
                      </li>
                    </>
                    :<></>
                  }
                  <li>
                      <StyleClass nodeRef={btnRef5} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                          <a ref={btnRef5} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                              <i className="pi pi-chart-line mr-2"></i>
                              <span>ANALYSIS</span>
                              <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                              <Ripple />
                          </a>
                      </StyleClass>
                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          <li>
                            <Link to="/analysis/paths" style={{ textDecoration: 'none' }}  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                <i className="pi pi-chart-line mr-2"></i>
                                <span>PATHS</span>
                                <Ripple />
                            </Link>
                          </li>
                          {(datacastProperties.admin)?
                            <>
                              <li>
                                  <Link to="/analysis/streams" style={{ textDecoration: 'none' }}  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                      <i className="pi pi-chart-line mr-2"></i>
                                      <span>STREAMS</span>
                                      <Ripple />
                                  </Link>
                              </li>
                              <li>
                                  <Link to="/analysis/servers" style={{ textDecoration: 'none' }}  className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                      <i className="pi pi-chart-line mr-2"></i>
                                      <span>SERVERS</span>
                                      <Ripple />
                                  </Link>
                              </li>
                            </>
                            :<></>
                          }
                        </ul>
                    </li>
                </ul>
              </div>
              <div className="mt-auto">

                <hr className='ml-1 w-full'></hr>
                <ul className="list-none p-3 m-0">
                <li>
                      <Link to="/settings" style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                          <i className="pi pi-cog mr-2"></i>
                          <span>SETTINGS</span>
                          <Ripple />
                      </Link>
                </li>
                {(datacastProperties.admin)?
                  <li>
                    <Link to='/admin' style={{ textDecoration: 'none' }} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                        <i className="pi pi-cog mr-2"></i>
                        <span className="font-medium">ADMIN</span>
                        <Ripple />
                    </Link>
                  </li>
                :<></>
                }
                <li>
                  <Link to='/' style={{ textDecoration: 'none' }} onClick={logout} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                      <i className="pi pi-sign-out mr-2"></i>
                      <span className="font-medium">LOGOUT</span>
                      <Ripple />
                  </Link>
                </li>
                </ul>
                <div className='flex justify-content-center mx-3'>
                    <div className='text-white p-1' style={{fontSize:'.55rem'}}>INTERNATIONAL DATACASTING
                      <span className='text-white' style={{fontSize:'.5rem'}}> &copy; 2023</span>
                    </div>
                </div>
                
              </div>            
            </div>
          </div>

        {/*Body Content*/}
        <div className="min-h-screen flex flex-column relative flex-auto">
          <div className="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border" style={{ height: '80px' }}>
            <div className='flex'>
              <StyleClass nodeRef={btnRef4} selector="#app-sidebar-1" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
                <a ref={btnRef4} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                    <i className="pi pi-bars text-4xl"></i>
                    <Ripple />
                </a>
              </StyleClass>
              <span className='justify-content-center'>
                <Image src="img/cloud.png" alt="Logo" />
                <span className='ml-3'>{datacastProperties.networkName}</span>
              </span>
            </div>
            <StyleClass nodeRef={btnRef6} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                <a ref={btnRef6} className="p-ripple cursor-pointer block lg:hidden text-700">
                    <i className="pi pi-ellipsis-v text-2xl"></i>
                    <Ripple />
                </a>
            </StyleClass>
            <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
                            surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
              <li>
                <AlertNotification />
              </li>
              <li className="border-top-1 surface-border lg:border-top-none">
                <Link to="/settings" style={{ textDecoration: 'none' }}className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                                transition-duration-150 transition-colors w-full">
                  <div className="text-900 font-medium">{datacastProperties.name}</div>
                  <Ripple />
                </Link>
              </li>
              <li className="border-top-1 surface-border lg:border-top-none">
                  <Button className='p-button-rounded p-button-text text-3xl' icon="pi pi-info-circle" style={{'fontSize': '2em'}} onClick={()=>setShowAbout(true)}/>
                  <div className="text-900 font-medium lg:hidden">About</div>
                  <Ripple />
              </li>
            </ul>
          </div>
          <div className="p-5 flex flex-column flex-auto">
            <Routes>
              <Route path="/admin" exact element={<Admin />} />
              <Route path="/agents" exact element={<Agents />} />
              <Route path="/creategroup" exact element={<CreateGroup />} />
              <Route path="/createagentgroup" exact element={<CreateAgentGroup />} />
              <Route path="/editagentgroup" exact element={<EditAgentGroup />} />
              <Route path="/editgroup" exact element={<EditGroup />} />
              <Route path="/createagent" exact element={<CreateAgent />} />
              <Route path="/editagent" element={<EditAgent />} />
              <Route path="/viewagent" element={<ViewAgent />} />
              <Route path="/addstream" exact element={<AddStream />} />
              <Route path="/settings" exact element={<Settings />} />
              <Route path="/" exact element={<Status />} />
              <Route path="/editstream" exact element={<EditStream />} />
              <Route path="/streams" exact element={<Streams cloud={datacastIp} />} />
              <Route path="/analysis/paths" exact element={<PathAnalysis cloud={datacastIp} />} />
              <Route path="/analysis/streams" exact element={<StreamAnalysis />} />
              <Route path="/analysis/servers" exact element={<ServerAnalysis cloud={datacastIp} />} />
              <Route path="/servers" exact element={<Servers cloud={datacastIp} />} />
              <Route path="/srtserver/*" element={<SrtServer />} />
              <Route path="/alerts" element={<Alerts cloud={datacastIp}/>} />
              <Route path="/logout" element={<Navigate to='/' />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </div>
        </div>
        </div>
      </Router>
    );
  }
  else {
    
    return(
      <Router>
         <Routes>
            <Route path="/" exact element={<CreateCortex />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
      </Router>
    )
  }
}

export default App;
