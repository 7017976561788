
export const DbUsers= async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/users`, requestOptions);
    const data = await response.json();

    if(data.error === 0) {
        return data.data;
    }

    return [];
}


export const DbUser = async (db, id) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/users/${id}`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbUserSet = async (db, user) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(`/api/v1/mistiq/users`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbUserDestroy = async (db, uuid) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    const response = await fetch(`/api/v1/mistiq/users/${uuid}`, requestOptions);
    const data = await response.json();

    return data;
}


export const DbCreateUser = async (db, userInfo) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userInfo)
    };

    const response = await fetch(`/api/v1/mistiq/users/register`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbUserSession = async (db, id, info) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    };

    const response = await fetch(`/api/v1/mistiq/users/${id}/session`, requestOptions);
    const result = await response.json();
    if(result.error === 0) {
        return result.data
    }

    return null;
}

export const DbUserSessionClose = async (db, session) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(session)
    };

    const response = await fetch(`/api/v1/mistiq/users/session`, requestOptions);
    const data = await response.json();

    return data;
}

export const DbLogin = async (db, userInfo) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userInfo)
    };

    const response = await fetch(`/api/v1/mistiq/users/login`, requestOptions);
    const data = await response.json();

    return data;
}